import React from 'react';
import PageSection from 'ui-kit/page-section/page-section';
import PageLayout from 'components/layouts/page/page.layout';
import { graphql, useStaticQuery } from 'gatsby';
import { useSelector } from 'react-redux';
import { accountIsLoggedInSelector } from 'state/account/account.selectors';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { CenteredHero } from 'components/centered-text-hero/centered-hero.component';

const NotFound = () => {
    const { t } = useTranslation();
    const loggedIn = useSelector(accountIsLoggedInSelector);
    const imageData = useStaticQuery(graphql`
        query {
            notFoundDesktop: file(relativePath: { eq: "assets/images/not-found-background.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
        }
    `);

    return (
        <PageLayout metaData={{ nodeTitle: 'Page Not Found' }}>
            <PageSection className="text-center">
                <CenteredHero
                    image={imageData.notFoundDesktop}
                    eyebrowText={t('pages.notFound.404')}
                    linkTo={`${loggedIn ? '/secure/profile/personal-info' : '/'}`}
                    linkLabel={loggedIn ? t('pages.notFound.ctas.loggedIn') : t('pages.notFound.ctas.loggedOut')}
                    title={t('pages.notFound.flewTheCoop')}
                    subtitle={t('pages.notFound.cantFindPage')}
                    classNames="centered-hero--full-page"
                />
            </PageSection>
        </PageLayout>
    );
};

export default NotFound;
